/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import tgLogoWhite from '@images/tgLogoRed/tg_logo_red.png';
import amasyaEt from '@images/amasyaEt.png';
import beyPilic from '@images/beypilic.png';
import appleStore from '@images/appleStore.png';
import googlePlayStore from '@images/googlePlayStore.png';
import neYediginiBilRed from '@images/neYediginiBilRed/ne-yedigin.jpg';
import yerliUretim from '@images/yerliUretim/yerli.jpg';
import { analyticsFooterClick } from 'utils/analyticsUtils';
import Etbis from '../../Etbis';

import FooterAccordion from './FooterAccordion';
import SocialButtons from './SocialButtons/index';
import './footer.scss';
import * as jsonData from './footer.json';

function Footer({ collapse }) {
  const data = useStaticQuery(
    graphql`
      query {
        brands: allStrapiBrand(sort: { fields: DisplayOrder }) {
          edges {
            node {
              Name
              CorporateSiteLink
              WeightInformation {
                publicURL: url
              }
              CertificateLink
              DisplayOrder
              CRMId
              NormalizedName
              Splash {
                publicURL: url
              }
              Logo {
                publicURL: url
              }
            }
          }
        }
      }
    `,
  );

  const brands = data?.brands;

  return (
    <footer className={`footer line ${collapse ? 'overflow' : ''}`}>
      <Container className="footer-top">
        <Row className="align-items-center">
          <Col>
            <div className="store">
              <a
                target="_system"
                href="https://apps.apple.com/tr/app/t%C4%B1kla-gelsin/id1534477610?l=tr"
                onClick={(e) => {
                  analyticsFooterClick({
                    category: 'Store',
                    element: 'AppStore',
                    path: e.currentTarget.href,
                  });
                }}
              >
                <img src={appleStore} alt="Tıkla Gelsin App Ios" className="store-logo" />
              </a>
              <a
                target="_system"
                href="https://play.google.com/store/apps/details?id=com.ataexpress.tiklagelsin"
                onClick={(e) => {
                  analyticsFooterClick({
                    category: 'Store',
                    element: 'GooglePlay',
                    path: e.currentTarget.href,
                  });
                }}
              >
                <img src={googlePlayStore} alt="Tıkla Gelsin App Android" className="store-logo" />
              </a>
            </div>
          </Col>
          <Col xs="auto">
            <SocialButtons />
          </Col>
        </Row>
        <div className="divider mt-0" />
      </Container>
      <Container className="footer-main">
        <Row>
          <Col md={6} lg={3} className="logo-container">
            <div className="box pb-0">
              <img src={tgLogoWhite} alt="Tıkla Gelsin Logo" className="logo" width="115" height="65" />
              <div>
                <a href="mailto:bilgi@tiklagelsin.com">
                  <span>bilgi@tiklagelsin.com</span>
                </a>
              </div>
              <div className="divider d-md-none d-sm-block" />
            </div>
          </Col>
          <Col md={6} lg={3}>
            {/* Site Haritası */}
            <div className="box pb-4">
              <div className="bold">Site Haritası</div>

              <div className="footer-accordion">
                <ul className="accordion-item-list">
                  <li className="accordion-item pt-0">
                    <Link
                      to="/"
                      onClick={() => {
                        analyticsFooterClick({
                          category: 'Site Haritası',
                          element: 'Ana Sayfa',
                          path: '/',
                        });
                      }}
                    >
                      Ana Sayfa
                    </Link>
                  </li>
                  <li className="accordion-item">
                    <Link
                      to="/kampanyalar"
                      onClick={() => {
                        analyticsFooterClick({
                          category: 'Site Haritası',
                          element: 'Kampanyalar',
                          path: '/kampanyalar',
                        });
                      }}
                    >
                      Kampanyalar
                    </Link>
                  </li>
                  <li className="accordion-item">
                    <a
                      href="/a/account"
                      onClick={() => {
                        analyticsFooterClick({
                          category: 'Site Haritası',
                          element: 'Hesabım',
                          path: '/a/account',
                        });
                      }}
                    >
                      Hesabım
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col md={6} lg={3}>
            <div className="box pb-4">
              <div className="bold">Bilgilendirme</div>
              <div className="footer-accordion">
                <ul className="accordion-item-list">
                  <li className="accordion-item pb-3 pt-0">
                    <FooterAccordion
                      title="Restoran Kurumsal Sayfalar"
                      data={jsonData?.footer?.accordion?.restaurants?.links}
                    />
                  </li>
                  <li className="accordion-item pb-3">
                    <FooterAccordion
                      title="Ürün ve Gramaj Bilgileri"
                      data={jsonData?.footer?.accordion?.productAndWeight?.links}
                    />
                  </li>
                  <li className="accordion-item">
                    <Link
                      to="/aydinlatma-metni"
                      onClick={() => {
                        analyticsFooterClick({
                          category: 'Bilgilendirme',
                          element: 'KVKK Aydınlatma Metni',
                          path: '/aydinlatma-metni',
                        });
                      }}
                    >
                      KVKK Aydınlatma Metni
                    </Link>
                  </li>
                  <li className="accordion-item">
                    <Link
                      to="/cerez-aydinlatma-metni"
                      onClick={() => {
                        analyticsFooterClick({
                          category: 'Bilgilendirme',
                          element: 'Çerez Aydınlatma Metni',
                          path: '/cerez-aydinlatma-metni',
                        });
                      }}
                    >
                      Çerez Aydınlatma Metni
                    </Link>
                  </li>
                  <li className="accordion-item">
                    <FooterAccordion
                      title={jsonData?.footer?.accordion?.clarificationTexts?.title}
                      data={jsonData?.footer?.accordion?.clarificationTexts?.links}
                    />{' '}
                  </li>
                  <li className="accordion-item">
                    <a
                      target="_blank"
                      href="https://e-sirket.mkk.com.tr/esir/Dashboard.jsp#/sirketbilgileri/21771"
                      onClick={(e) => {
                        analyticsFooterClick({
                          category: 'Bilgilendirme',
                          element: 'Bilgi Toplumu Hizmetleri',
                          path: e.currentTarget.href,
                        });
                      }}
                    >
                      Bilgi Toplumu Hizmetleri
                    </a>
                  </li>
                  <li className="accordion-item">
                    <a
                      target="_blank"
                      href="/contact"
                      onClick={() => {
                        analyticsFooterClick({
                          category: 'Bilgilendirme',
                          element: 'İletişim',
                          path: '/contact',
                        });
                      }}
                    >
                      İletişim
                    </a>
                  </li>
                  <li className="accordion-item">
                    <a
                      target="_blank"
                      href="/blog"
                      onClick={() => {
                        analyticsFooterClick({
                          category: 'Bilgilendirme',
                          element: 'Blog',
                          path: '/blog',
                        });
                      }}
                    >
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
          <Col md={6} lg={3}>
            <div className="box box-no-border">
              <div className="bold">Sertifikalar</div>
              <p className="bold">Et ürünlerimiz yerli üreticilerde helal sertifikalı etten üretilmektedir.</p>
              <FooterAccordion title="Sertifikalar" data={brands?.edges} />
              <div className="home-production">
                <a
                  target="_system"
                  href="https://www.neyediginibil.com/"
                  onClick={(e) => {
                    analyticsFooterClick({
                      category: 'Footer',
                      element: 'Yerli Üretim',
                      path: e.currentTarget.href,
                    });
                  }}
                >
                  <img
                    src={yerliUretim}
                    style={{ backgroundColor: '#FFFFFF' }}
                    alt="Yerli Üretim"
                    width="49"
                    height="22"
                  />
                </a>
              </div>
            </div>
          </Col>
        </Row>
        <div className="divider" />
      </Container>
      <Container className="footer-bottom">
        <Row className="align-items-center justify-content-center pt-1 pb-2">
          <Col xs="auto" style={{ marginLeft: 'auto' }}>
            <img src={amasyaEt} alt="Amasya Et Ürünleri" height="56" width="auto" />
          </Col>
          <Col xs="auto">
            <img src={beyPilic} alt="Beypiliç" height="56" width="auto" />
          </Col>
          <Col xs="auto">
            <a
              target="_system"
              href="https://www.neyediginibil.com/"
              onClick={(e) => {
                analyticsFooterClick({
                  category: 'Footer',
                  element: 'Ne yediğini bil!',
                  path: e.currentTarget.href,
                });
              }}
            >
              <img src={neYediginiBilRed} alt="Ne yediğini bil!" height="56" width="auto" />
            </a>
          </Col>
          <Col xs="auto" style={{ marginLeft: 'auto' }}>
            <Etbis />
          </Col>
        </Row>
        <div className="divider" />
      </Container>
      <Container>
        <div className="d-flex justify-content-center align-items-center">
          <div className="allRightsReversedTitle" translate="no">
            {`Ata Express® ${new Date()?.getFullYear()}. Tüm hakları saklıdır.`}
          </div>
        </div>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  collapse: PropTypes.bool,
};

// Footer.defaultProps = {}

export default Footer;
