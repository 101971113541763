import React from 'react';
import './socialButtons.scss';

const SocialButtons = () => (
  <nav className="social-button">
    <ul className="social-button-items">
      <li className="social-button-item">
        <a
          href="https://www.facebook.com/tiklagelsin1/about"
          target="_blank"
          rel="noopener norefferer"
          aria-label="Facebook"
        >
          <i className="icon-tg-facebook">
            <span className="path1" />
            <span className="path2" />
          </i>
        </a>
      </li>
      <li className="social-button-item">
        <a href="https://twitter.com/tiklagelsin_" target="_blank" rel="noopener norefferer" aria-label="Twitter">
          <i className="icon-tg-twitter">
            <span className="path1" />
            <span className="path2" />
          </i>
        </a>
      </li>
      <li className="social-button-item">
        <a href="https://instagram.com/tikla.gelsin" target="_blank" rel="noopener norefferer" aria-label="Instagram">
          <i className="icon-tg-instagram">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
            <span className="path4" />
          </i>
        </a>
      </li>
      <li className="social-button-item">
        <a href="https://www.youtube.com/@Tikla.Gelsin" target="_blank" rel="noopener norefferer" aria-label="YouTube">
          <i className="icon-tg-youtube">
            <span className="path1" />
            <span className="path2" />
          </i>
        </a>
      </li>
      <li className="social-button-item">
        <a href="https://www.tiktok.com/@tikla.gelsin" target="_blank" rel="noopener norefferer" aria-label="TikTok">
          <i className="icon-tg-tiktok">
            <span className="path1" />
            <span className="path2" />
          </i>
        </a>
      </li>
      <li className="social-button-item">
        <a
          href="https://whatsapp.com/channel/0029VaoA3rT3wtazwJxRJm2O"
          target="_blank"
          rel="noopener norefferer"
          aria-label="WhatsApp"
        >
          <i className="icon-tg-whatsapp">
            <span className="path1" />
            <span className="path2" />
            <span className="path3" />
          </i>
        </a>
      </li>
    </ul>
  </nav>
);

SocialButtons.propTypes = {};

export default SocialButtons;
